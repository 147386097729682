<template>
  <div>
    <div class="card rounded" :class="showingFullScreen ? 'full-screen-card' : ''">
      <div class="bushel-card-header d-flex">
        <div class="p-2" :class="dropdownWrapperClass">
          <b-dropdown id="dropdown-1" :label="activeTab" variant="primary">
            <b-dropdown-item
              v-for="tab in tabs"
              :key="tab.name"
              :active="activeTab == tab.name"
              :disabled="disableTabChange"
              @click="$emit('changeTab', tab.name)"
            >
              {{ tab.name }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div :class="tabWrapperClass">
          <div class="p-2">
            <BushelBtn
              v-for="tab in tabs"
              :key="tab.name"
              class="px-4 py-2"
              :class="tabClass"
              :disabled="disableTabChange"
              :flat="activeTab == tab.name ? false : true"
              :variant="activeTab == tab.name ? activeTabClass : 'secondary'"
              @click="$emit('changeTab', tab.name)"
            >
              <b-spinner v-if="tab.attention" type="grow" small />
              <span>{{ tab.name }}</span>
            </BushelBtn>
          </div>
        </div>
        <div class="spacer" />
        <slot name="header-bar-second-to-end" />
        <BushelBtn v-if="selectedArticle" variant="primary" flat class="tab-card-button" @click="showArticle">
          <ImgIcon icon="pop-icon" />
        </BushelBtn>
        <BushelBtn
          v-if="allowFullscreen"
          :variant="allowFullscreenVariant"
          :flat="allowFullscreenFlat"
          :outline="allowFullscreenOutline"
          class="tab-card-button"
          :class="allowFullscreenClass"
          :style="allowFullscreenStyle"
          :disabled="disabled"
          @click="showingFullScreen = !showingFullScreen"
        >
          <i class="fa-fw" :class="showingFullScreen ? 'fas fa-compress' : 'fas fa-expand'" />
        </BushelBtn>
        <BushelBtn v-if="authorized && type != 'blank'" variant="success" class="tab-card-button" :disabled="disabled" @click="$emit('click')">
          <i
            class="fa-fw"
            :class="{
              'fa fa-plus': type === 'add',
              'fa fa-pencil-alt': type === 'edit',
              'fa fa-upload': type === 'upload',
              'fas fa-sync-alt': type === 'refresh',
              'fa fa-spin': spin,
            }"
          />
        </BushelBtn>
      </div>
      <div class="bushel-card-body" :class="[bodyClass, { 'rounded-bottom': !hasFooterSlot }]">
        <slot />
      </div>
      <div v-if="hasFooterSlot" class="card-footer" :class="footerClass">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import BSpinner from "@/components/base/BSpinner.vue";
import ImgIcon from "@/components/common/icons/ImgIcon.vue";
import BDropdown from "@/components/base/BDropdown.vue";
import BDropdownItem from "@/components/base/BDropdownItem.vue";

export default {
  components: { BSpinner, ImgIcon, BDropdown, BDropdownItem },
  props: {
    disableTabChange: { type: Boolean, default: false },
    authorized: { type: Boolean, default: false },
    type: { type: String, default: "blank" },
    spin: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    dropdownWrapperClass: { type: String, default: "d-md-none" },
    tabWrapperClass: { type: String, default: "d-none d-md-block" },
    bodyClass: { type: String, required: false },
    titleClass: { type: String, required: false },
    footerClass: { type: String, required: false },
    tabs: { type: Array, required: true },
    activeTab: { type: String, required: true },
    tabClass: { type: String, default: "" },
    apexArticleSlug: { type: String },
    bushelArticleSlug: { type: String },
    allowFullscreen: { type: Boolean, default: false },
    startFullscreen: { type: Boolean, default: false },
    allowFullscreenFlat: { type: Boolean, default: false },
    allowFullscreenOutline: { type: Boolean, default: false },
    allowFullscreenFull: { type: Boolean, default: false },
    allowFullscreenVariant: { type: String, default: "light" },
    allowFullscreenClass: { type: String, default: null },
    allowFullscreenStyle: { type: String, default: null },
    activeTabClass: { type: String, default: "light-primary" },
  },
  emits: ["changeTab", "click"],
  data() {
    return {
      industry: APP_INDUSTRY,
      showingFullScreen: false,
    };
  },
  computed: {
    ...mapState({
      supportArticleDrawerOpen: (state) => state.ui.supportArticleDrawer.open,
      supportArticleDrawerSlug: (state) => state.ui.supportArticleDrawer.articleSlug,
    }),
    selectedArticle() {
      if (this.apexArticleSlug && this.apexArticleSlug.length && this.industry == "cannabis") {
        return this.apexArticleSlug;
      }
      if (this.bushelArticleSlug && this.bushelArticleSlug.length && this.industry == "hemp") {
        return this.bushelArticleSlug;
      }
      return null;
    },
    hasFooterSlot() {
      return this.$slots.footer;
    },
  },
  mounted() {
    if (this.startFullscreen) {
      this.showingFullScreen = true;
    }
  },
  methods: {
    ...mapActions("ui", ["handleSupportArticleDrawer"]),
    showArticle() {
      if (!this.supportArticleDrawerOpen || this.supportArticleDrawerSlug != this.selectedArticle) {
        this.handleSupportArticleDrawer(this.selectedArticle);
      } else {
        this.handleSupportArticleDrawer();
      }
    },
  },
};
</script>

<style scoped>
:deep(.nav-link) {
  color: #49545a;
}

:deep(.nav-tabs) {
  border: none;
}
</style>

<style scoped lang="scss">
@import "@sass/_variables.scss";
@import "@sass/_mixins.scss";
.card {
  background-color: transparent;
}

.card-header {
  border-bottom: none;
}

.tab-card-button {
  margin: 0.5rem;
  width: 52px;
}

.tab-card-button:disabled {
  background: linear-gradient(110.6deg, #97be79 100%, #a3c56b 0%, #c5d845 0%);
}

.card-body {
  padding: 0px;
  background-color: #c5c5c5ad !important;
}

.full-screen-card {
  position: absolute;
  height: 100%;
  padding: 0px;
  margin: 0px;
  @include border-radius(0px !important);
  width: 100%;
  left: 0;
  top: 0px;
  // overflow: hidden; this doesn't allow scrolling
  // overflow-y: scroll;
  // overflow-x: hidden;
  &.full-card {
    z-index: 100;
    top: -40px;
    height: calc(100% + 40px);
  }
  .bushel-card-header {
    @include border-radius(0px !important);
  }
}
.sidebarSticky .full-screen-card {
  top: 40px;
  // left: -224px !important;
  // width: calc(100% + 224px) !important;
}
@media (min-width: map-get($grid-breakpoints, sm) + 1px) {
  .full-screen-card {
    //
    &.full-card {
      //
    }
  }
}
@media (min-width: map-get($grid-breakpoints, md) + 1px) {
  .full-screen-card {
    // left: -50px;
    // width: calc(100% + 50px);
    &.full-card {
      top: -60px;
      height: calc(100% + 60px);
    }
  }
  .sidebarSticky .full-screen-card {
    top: 0px;
    // left: -224px !important;
    // width: calc(100% + 224px) !important;
  }
}
@media (min-width: map-get($grid-breakpoints, lg) + 1px) {
  .full-screen-card {
    //
    &.full-card {
      //
    }
  }
}
</style>
