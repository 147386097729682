import { createApp } from "vue";
import * as Sentry from "@sentry/vue";
import store from "./store";
import router from "./router";
import plugins from "./plugins";
import App from "./App.vue";
import "@sass/index.scss";

// initialize app
const app = createApp(App);

function isProduction() {
  const productionDomains = ["app.apextrading.com", "secure.bushel44.com"];
  return productionDomains.includes(window.location.hostname);
}

 
const environment = APP_ENVIRONMENT ?? "unset";

// sentry
Sentry.init({
  // beforeSend(event) {
  //   // Check if this is a CSS preload error
  //   if (event.exception &&
  //       event.exception.values &&
  //       event.exception.values[0] &&
  //       event.exception.values[0].value &&
  //       event.exception.values[0].value.includes('Unable to preload CSS')) {
  //     // Don't send this event to Sentry
  //     return null;
  //   }

  //   // For network errors related to CSS files
  //   if (event.exception &&
  //       event.request &&
  //       event.request.url &&
  //       event.request.url.includes('/build/assets/') &&
  //       event.request.url.endsWith('.css')) {
  //     return null;
  //   }

  //   // Send all other events normally
  //   return event;
  // },

  app,
  dsn: "https://e1c828e0bc4e328820127351d0726050@o4508812639797248.ingest.us.sentry.io/4508812641959936",
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: isProduction() ? 0.5 : 0.5, //  Capture 50% of the transactions
  environment,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    // Apex Trading domains
    /^https:\/\/app\.apextrading\.com/,
    /^https:\/\/beta\.apextrading\.com/,
    /^https:\/\/demo\.apextrading\.com/,
    /^https:\/\/.*\.apextrading\.com\/api/,
    // Bushel44 domains
    /^https:\/\/beta\.bushel44\.com/,
    /^https:\/\/demo\.bushel44\.com/,
    /^https:\/\/secure\.bushel44\.com/,
    /^https:\/\/.*\.bushel44\.com\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: isProduction() ? 0.01 : 0.1, // This sets the sample rate at 1% in prod, 10% while in development
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

window.addEventListener(
  "error",
  (event) => {
    // this is not catching what is expected, and catching things we wouldn't want to refresh the page for
    // if (event.target && (event.target.src || event.target.href)) {
    //   Sentry.captureMessage(`Resource load error: ${event.target.tagName}`, {
    //     extra: {
    //       tagName: event.target.tagName,
    //       src: event.target.src || event.target.href,
    //       type: event.type,
    //     },
    //     level: "warning",
    //   });
    //   // return;
    // }

    if (event.message && typeof event.message === "string") {
      Sentry.captureMessage(`Error in event listener: ${event.message}`);
    }

    // For errors in the message (like the "Unable to preload CSS" message)
    if (event.message && typeof event.message === "string" && event.message.includes("Unable to preload CSS")) {
      Sentry.captureMessage("CSS preload failed", {
        extra: { originalMessage: event.message },
        level: "warning",
      });
      // return;
    }

    if (event.message && typeof event.message === "string" && event.message.includes("Failed to fetch dynamically imported module")) {
      Sentry.captureMessage("Failed to fetch dynamically imported module captured in error event listener");

      // window.location.reload(true);
    }
  },
  true,
);

window.addEventListener("vite:preloadError", (event) => {
  // Sentry.captureMessage(`Vite preload error: ${event.data?.url || "unknown resource"}`, {
  //   extra: {
  //     errorMessageRoot: event.errorMessage,
  //     type: event.type,
  //     url: event.data?.url,
  //     errorMessage: event.data?.error?.message,
  //     errorStack: event.data?.error?.stack,
  //     elementType: event.target?.tagName,
  //     elementSrc: event.target?.src || event.target?.href,
  //   },
  //   level: "warning",
  // });
  Sentry.captureMessage(`Vite preload error 1`);
  Sentry.captureMessage(`Vite preload error`, {
    extra: {
      errorPayload: event.payload,
      type: event.type,
    },
  });
  // window.location.reload(true);
});

// use store and router
app.use(store);
app.use(router);

// use plugins
plugins(app);

// mount app
app.mount("#app");
